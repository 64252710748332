<template>
    <section class="pedidos-formulario-envio">
        <div class="d-middle px-3 py-2 text-general">
            <el-tooltip effect="light" content="Regresar al pedido">
                <i class="icon-left-circle text-black2 f-20 cr-pointer" @click="goBackToOrder" />
            </el-tooltip>
            <p class="fr-medium f-18">ID. {{ this.$route.params.id_pedido }} - Enviar pedido por paquetería</p>
        </div>
        <div class="d-flex">
            <div class="flex-fill">
                <el-tabs v-model="activeName" stretch @tab-click="handleClick">
                    <!-- direccion origen -->
                    <el-tab-pane name="first">
                        <span slot="label">
                            <div class="d-middle-center px-2">
                                <p class="text-general2 f-14 fr-light"> 1. Dirección de origen </p>
                                <i v-if="iconOrigen" class="icon-ok-circled-outline f-20 text-success mr-2" />
                                <i v-else class="icon-attention-alt f-20 text-warning mr-2" />
                            </div>
                        </span>
                        <div class="container-fluid">
                            <ValidationObserver ref="validatorOrigen">
                                <!-- nombre + celular -->
                                <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="nombre">
                                            <p class="pl-2 text-general f-15"> Nombre </p>
                                            <el-input v-model="modelOrigen.nombre" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:10|numeric" name="telefono">
                                            <p class="pl-2 text-general f-15"> telefono </p>
                                            <el-input v-model="modelOrigen.celular" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- pais + calle -->
                                <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required" name="país">
                                            <p class="pl-2 text-general f-15"> País </p>
                                            <el-select v-model="modelOrigen.id_pais" placeholder="Seleccionar" filterable class="w-100 br-12" @change="obtenerEstados">
                                                <el-option v-for="item in paises" :key="item.code" :label="item.name" :value="item.code" :disabled="item.disabled" />
                                            </el-select>
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:150" name="calle">
                                            <p class="pl-2 text-general f-15"> Calle </p>
                                            <el-input v-model="modelOrigen.calle" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- numero + codigo postal + colonia -->
                                <div class="row">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required:max:50" name="numero">
                                            <p class="pl-2 text-general f-15"> Número </p>
                                            <el-input v-model="modelOrigen.numero" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3 mt-auto">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:8|numeric" name="codigo postal">
                                            <p class="pl-2 text-general f-15"> Código postal </p>
                                            <el-input v-model="modelOrigen.codigo_postal" :disabled="modelOrigen.id_pais == null ? true :false " size="large" class="br-12" @change="obtenerEstadoCiudadOrigen" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="max:45" name="colonia">
                                            <p class="pl-2 text-general f-15"> Colonia (opcional) </p>
                                            <el-input v-model="modelOrigen.colo" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- ciudad + estado -->
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:50" name="ciudad origen">
                                            <p class="pl-2 text-general f-15"> Ciudad </p>
                                            <el-input v-model="modelOrigen.ciudad" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="required" name="estado origen">
                                            <p class="pl-2 text-general f-15"> Estado </p>
                                            <el-select v-model="modelOrigen.id_estado" placeholder="Seleccionar" class="w-100 br-12">
                                                <el-option v-for="item in estadosOrigen" :key="item.code_2_digits" :label="item.name" :value="item.code_2_digits" />
                                            </el-select>
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- nit + referencia -->
                                <div class="row my-4">
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="required:max:20|numeric" name="nit">
                                            <p class="pl-2 text-general f-15"> Nit </p>
                                            <el-input v-model="modelOrigen.nit" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="max:100" name="estado">
                                            <p class="pl-2 text-general f-15"> Referencia (opcional) </p>
                                            <el-input v-model="modelOrigen.referencia" size="large" placeholder="Añade una referencia" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                            <div class="row">
                                <div class="col-auto">
                                    <button class="btn br-10 bg-general text-white h-32px w-123px f-14" @click="validarOrigen('second')">
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <!-- direccion destino -->
                    <el-tab-pane name="second">
                        <span slot="label" @click.stop="validarOrigen('second')">
                            <div class="d-middle-center px-2">
                                <p class="text-general2 f-14 fr-light"> 2. Dirección de destino </p>
                                <i v-if="iconDestino" class="icon-ok-circled-outline f-20 text-success mr-2" />
                                <i v-else class="icon-attention-alt f-20 text-warning mr-2" />
                            </div>
                        </span>
                        <div class="container-fluid">
                            <ValidationObserver ref="validatorDestino">
                                <!-- nombre + celular -->
                                <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="nombre">
                                            <p class="pl-2 text-general f-15"> Nombre </p>
                                            <el-input v-model="modelDestino.nombre" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:10|numeric" name="telefono destino">
                                            <p class="pl-2 text-general f-15"> Telefono </p>
                                            <el-input v-model="modelDestino.celular" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- pais + direccion -->
                                <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required" name="país destino">
                                            <p class="pl-2 text-general f-15"> País </p>
                                            <el-select v-model="modelDestino.id_pais" placeholder="Seleccionar" class="br-12 w-100" @change="obtenerEstadosDestino">
                                                <el-option v-for="item in paises" :key="item.code" :label="item.name" :value="item.code" :disabled="item.disabled" />
                                            </el-select>
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:150" name="calle destino">
                                            <p class="pl-2 text-general f-15"> Calle </p>
                                            <el-input v-model="modelDestino.calle" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- numero + codigo postal + colonia -->
                                <div class="row">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:50" name="numero destino">
                                            <p class="pl-2 text-general f-15"> Número </p>
                                            <el-input v-model="modelDestino.numero" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3 mt-auto">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:8|numeric" name="codigo postal destino">
                                            <p class="pl-2 text-general f-15"> Código postal </p>
                                            <el-input v-model="modelDestino.codigo_postal" :disabled="modelDestino.id_pais == null ? true : false" size="large" class="br-12" @change="obtenerEstadoCiudadDestino" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <ValidationProvider v-slot="{errors}" rules="max:45" name="colonia destino">
                                            <p class="pl-2 text-general f-15"> Colonia (opcional) </p>
                                            <el-input v-model="modelDestino.colonia" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- ciudad + estado -->
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:50" name="ciudad destino">
                                            <p class="pl-2 text-general f-15"> Ciudad </p>
                                            <el-input v-model="modelDestino.ciudad" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="required" name="estado destino">
                                            <p class="pl-2 text-general f-15"> Estado </p>
                                            <el-select v-model="modelDestino.id_estado" placeholder="Seleccionar" class="w-100 br-12">
                                                <el-option v-for="item in estadosOrigen" :key="item.code_2_digits" :label="item.name" :value="item.code_2_digits" />
                                            </el-select>
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- nit + referencia -->
                                <div class="row my-4">
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:20|numeric" name="nit">
                                            <p class="pl-2 text-general f-15"> Nit </p>
                                            <el-input v-model="modelDestino.nit" size="large" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider v-slot="{errors}" rules="max:100" name="estado">
                                            <p class="pl-2 text-general f-15"> Referencia (opcional) </p>
                                            <el-input v-model="modelDestino.referencia" size="large" placeholder="Añade una referencia" class="br-12" />
                                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                            <div class="row">
                                <div class="col-auto">
                                    <button class="btn br-10 h-32px border w-123px f-14" @click="setStep('first')">
                                        Atras
                                    </button>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="border-left wf-360px ">
                <div class="h-40px d-middle-center">
                    <p class="f-17 fr-medium text-general">Resumen</p>
                </div>
                <div class="card-origen d-flex px-2">
                    <div class="wh-24 bg-gris br-4 d-middle-center">
                        <i class="icon-map-marker-outline" />
                    </div>
                    <div class="ml-2">
                        <p class="f-14 text-general fr-semibold">{{ modelOrigen.nombre }}</p>
                        <p class="f-12 text-general">({{ modelOrigen.celular }})</p>
                        <p class="f-12 text-grey2">{{ modelOrigen.calle }}</p>
                    </div>
                </div>
                <div class="h-5px bg-whitesmoke4 w-100 my-3" />
                <div class="card-destination d-flex px-2">
                    <div class="wh-24 bg-gris br-4 d-middle-center">
                        <i class="icon-location-1" />
                    </div>
                    <div class="ml-2">
                        <p class="f-14 text-general fr-semibold">{{ modelDestino.nombre }}</p>
                        <p class="f-12 text-general">({{ modelDestino.celular }})</p>
                        <p class="f-12 text-grey2">{{ modelDestino.calle }}</p>
                    </div>
                </div>
                <div class="h-5px bg-whitesmoke4 w-100 my-3" />
                <div class="px-2">
                    <p class="pl-2 text-general f-15"> Transportista </p>
                    <el-select v-model="id_transpotadora" clearable filterable placeholder="Seleccionar empresa transportadora" class="w-100 br-12">
                        <el-option v-for="item in transportadoras" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                    <div v-if="id_transpotadora == null" class="my-3">
                        <!-- agregar attr disabled segun validaciones -->
                        <button v-loading="loading" class="btn btn-general text-white h-32px w-100" @click="cotizarEnvio">
                            Cotizar envío con todas
                        </button>
                    </div>
                    <div v-else class="my-3">
                        <button v-loading="loading" class="btn btn-general text-white h-32px w-100" @click="cotizarEnvio">
                            Cotizar envío
                        </button>
                    </div>
                </div>
                <div class="h-5px bg-whitesmoke4 w-100 my-3" />
                <div class="listado-paquetes px-2">
                    <card-package v-for="(item, index) in paquetes" :key="index" :item="item" class="mb-3" @openDelete="openModalDelete(index)" />
                </div>
                <div class="px-2">
                    <div class="my-3">
                        <!-- agregar attr disabled segun validaciones -->
                        <!-- <button class="btn btn-general text-white h-32px w-100" @click="openModalAddPackage"> -->
                        <button class="btn btn-general d-middle-center h-32px p-0 text-white w-100" @click="openModalAddPackage">
                            Agregar paquete
                            <i class="icon-package-add f-15" />
                        </button>
                    </div>
                </div>
                <!-- <div class="px-2 d-middle">
					<p class="f-14 text-general mr-2">Agregar paquete</p>
					<div class="wh-32 bg-general br-8 d-middle-center cr-pointer text-white" @click="openModalAddPackage">
						<i class="icon-plus" />
					</div>
				</div> -->
            </div>
        </div>
        <modal-cotizar-envio-todos ref="refModalCotizarEnvioTodos" />
        <modal-agregar-paquete ref="refModalAgregarPaquete" @agregarPaquete="agregarPaquete" />
        <modal-eliminar ref="refDeletePackage" titulo="Eliminar paquete" mensaje="¿Desea eliminar el paquete?" @eliminar="handleDelete" />
    </section>
</template>


<script>
import { mapGetters } from 'vuex'
import router from '~/router'
import Paqueteria from '../../../services/almacen/paqueteria'
import CardPackage from './components/cardPackage.vue'
import modalAgregarPaquete from './partials/modalAgregarPaquete.vue'
export default {
    components: {
        modalCotizarEnvioTodos: () => import('./partials/modalCotizarEnvioTodos'),
        CardPackage,
        modalAgregarPaquete,
    },
    data(){
        return {
            activeName: 'first',
            modelOrigen: {
                nombre: '',
                celular: '',
                id_pais: null,
                calle: '',
                numero: '',
                codigo_postal: '',
                colonia: '',
                ciudad: '',
                id_estado: '',
                nit: '',
                referencia: '',
                lat:'',
                lgn:''
            },
            modelDestino: {
                nombre: '',
                celular: '',
                id_pais: null,
                calle: '',
                numero: '',
                codigo_postal: '',
                colonia: '',
                ciudad: '',
                id_estado: '',
                nit: '',
                referencia: '',
                lat:'',
                lgn:''
            },
            tipo: '1',
            value: '',
            checked: false,
            paises:[],
            estadosOrigen:[],
            iconOrigen:false,
            iconDestino:false,
            transportadoras:[],
            id_transpotadora:null,
            paquetes:[],
            idxPaquete:null,
            cotizaciones:[],
            loading:false
        }
    },
    computed: {
        ...mapGetters({
            stateFormOrder:'pedidos/pedidos_admin/showFormOrder',
        }),
    },
    mounted(){
        this.obtenerPaises();
        this.obtenerInformacionInicial()
    },
    methods: {
        goBackToOrder(){
            this.$store.commit('pedidos/pedidos_admin/setStateFormOrder', !this.stateFormOrder)
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', this.$route.params.id_pedido)
        },
        handleClick(tab, event){
            console.log(tab, event);
        },
        setStep(step){
            this.activeName = step
        },
        async obtenerPaises(){
            try {
                const { data } = await Paqueteria.listarPaises()
                this.paises = data?.data ?? []
            } catch (error){
                this.notificacion('Error', 'Error al obtener paises', 'error')
            }
        },
        async obtenerEstados(){
            try {
                const { data } = await Paqueteria.listarEstados({codigo_pais:this.modelOrigen.id_pais})
                this.estadosOrigen = data?.data ?? []
                this.obtenerTransportadoras()
            } catch (error){
                this.notificacion('Error', 'Error al obtener paises', 'error')
            }
        },
        async obtenerEstadosDestino(){
            try {
                const { data } = await Paqueteria.listarEstados({codigo_pais:this.modelDestino.id_pais})
                this.estadosOrigen = data?.data ?? []
            } catch (error){
                this.notificacion('Error', 'Error al obtener paises', 'error')
            }
        },
        async obtenerTransportadoras(){
            try {
                const params = {
                    codigo_pais: this.modelOrigen.id_pais,
                    id_admin_pedido: this.$route.params.id_pedido
                }
                const { data } = await Paqueteria.listarTransportadoras(params)
                this.transportadoras = data?.data ?? []
            } catch (error){
                const mensaje = error?.response?.data?.error ?? "error al listar transportadoras"
                this.notificacion('Error', mensaje, 'error')
            }
        },
        async obtenerInformacionInicial(){
            try {
                const { data } = await Paqueteria.listarInformacionInicial(this.$route.params.id_pedido)
                const origen = data?.data?.origen ?? null
                const destino = data?.data?.destino ?? null
                if(origen != null){
                    this.modelOrigen.nombre = origen.nombre_usuario
                    this.modelOrigen.celular = origen.telefono
                    this.modelOrigen.calle = origen.direccion
                    this.modelOrigen.lat = origen.latitud
                    this.modelOrigen.lgn = origen.longitud
                }
                if (destino != null){
                    this.modelDestino.nombre = destino?.nombre_usuario ?? ''
                    this.modelDestino.celular = destino?.telefono ?? ''
                    this.modelDestino.calle = destino.direccion
                    this.modelDestino.lat = destino.latitud
                    this.modelDestino.lgn = destino.longitud
                }
            } catch (error){
                this.notificacion('Error', 'Error al obtener paises', 'error')
            }
        },
        async validarOrigen(step){
            const valid = await this.$refs.validatorOrigen.validate()
            if (!valid){
                this.iconOrigen = false
                return
            }
            this.iconOrigen = true
            this.activeName = step
        },
        async obtenerEstadoCiudadOrigen(val){
            try {
                if (this.modelOrigen.id_pais == null) return
                const { data } = await Paqueteria.informacionCodigoPostal({codigo_pais:this.modelOrigen.id_pais, codigo_postal: val})
                if (data?.data){
                    const estado = this.estadosOrigen.find(el => el.name.localeCompare(data.data.state.name, undefined, { sensitivity: 'base' }) === 0)
                    this.modelOrigen.ciudad = data.data.locality
                    this.modelOrigen.id_estado = estado.code_2_digits
                }else{
                    this.modelOrigen.ciudad = ''
                    this.modelOrigen.id_estado = ''
                }
            } catch (error){
                this.notificacion('Error', 'Error al consultar codigo postal', 'error')
            }
        },
        async obtenerEstadoCiudadDestino(val){
            try {
                if (this.modelDestino.id_pais == null) return
                const { data } = await Paqueteria.informacionCodigoPostal({codigo_pais:this.modelDestino.id_pais, codigo_postal:val})
                if (data?.data){
                    const estado = this.estadosOrigen.find(el => el.name == data.data.state.name)
                    this.modelDestino.ciudad = data.data.locality
                    this.modelDestino.id_estado = estado.code_2_digits
                }else{
                    this.modelDestino.ciudad = ''
                    this.modelDestino.id_estado = ''
                }
            } catch (error){
                this.notificacion('Error', 'Error al consultar codigo postal', 'error')
            }
        },
        async cotizarEnvio(){
            try {
                const validatorOrigen = await this.$refs.validatorOrigen.validate()
                const validatorDestino = await this.$refs.validatorDestino.validate()
                if (!validatorOrigen){
                    this.iconOrigen = false
                    this.notificacion('Error', "faltan campos por completar", 'error')
                    return
                }
                if (!validatorDestino){
                    this.iconDestino = false
                    this.notificacion('Error', "faltan campos por completar", 'error')
                    return
                }
                this.iconDestino = true
                if (this.paquetes.length == 0){
                    this.notificacion('mensaje', "Debes agregar paquetes", 'warning')
                    return
                }
                this.cotizaciones = []
                this.loading = true
                if (this.id_transpotadora == null || this.id_transpotadora == ''){
                    for (const key in this.transportadoras){
                		let value = this.transportadoras[key]
                        const payload = this.formatearData(value.name)
                        const { data } = await Paqueteria.cotizarEnvio(payload)
                        this.cotizaciones.push({
                            transportadora:value,
                            cotizacion:data,
                            informacionGuia:payload
                        })
                    }
                }else{
                    const trasnportadora = this.transportadoras.find(el => el.id == this.id_transpotadora)
                    const payload = this.formatearData(trasnportadora.name)
                    const { data } = await Paqueteria.cotizarEnvio(payload)
                    this.cotizaciones.push({
                        transportadora:trasnportadora,
                        cotizacion:data,
                        informacionGuia:payload
                    })
                }
                this.loading = false
                this.$refs.refModalCotizarEnvioTodos.toggle(this.cotizaciones)

            } catch (error){
                this.loading = false
                this.notificacion('Error', "Error al generar cotizacion", 'error')
            }

        },
        agregarPaquete(data){
            this.paquetes.push(data)
        },
        formatearData(nombreTransportadora){
            let dataPaquetes = []
            for (const key in this.paquetes){
                let value = this.paquetes[key]
                let paquete = {
                    "additionalServices": value.seguro != null && value.seguro > 0  ? [
                        {
                            "data":{
                                "amount":parseInt(value.seguro)
                            },
                            "service":"insurance"
                        }
                    ] : undefined,
                    "content": value.contenido,
                    "amount": parseInt(value.cantidad_paquetes),
                    "type": "box",
                    "weight": parseInt(value.peso),
                    "declaredValue": parseInt(value.valor_declarado),
                    "weightUnit": "KG",
                    "lengthUnit": "CM",
                    "dimensions": {
                        "length": parseInt(value.largo),
                        "width": parseInt(value.ancho),
                        "height": parseInt(value.alto),
                    }
                }
                dataPaquetes.push(paquete)
            }

            let payload = {
                "origin": {
                    "category":1,
                    "city": this.modelOrigen.ciudad,
                    "company": this.modelOrigen.nombre,
                    "country": this.modelOrigen.id_pais,
                    "district": this.modelOrigen.colonia,
                    "email": '',
                    "identificationNumber": this.modelOrigen.nit,
                    "name": this.modelOrigen.nombre,
                    "number": this.modelOrigen.numero,
                    "phone": this.modelOrigen.celular,
                    "postalCode": this.modelOrigen.codigo_postal,
                    "reference": this.modelOrigen.referencia,
                    "state": this.modelOrigen.id_estado,
                    "street": this.modelOrigen.calle,
                    // "coordinates": {
                    // 	"latitude": `${ this.modelOrigen.lat }`,
                    // 	"longitude": `${this.modelOrigen.lgn}`
                    // }
                },
                "destination": {
                    "category":1,
                    "city": this.modelDestino.ciudad,
                    "company": this.modelDestino.nombre,
                    "country": this.modelDestino.id_pais,
                    "district": this.modelDestino.colonia,
                    "name": this.modelDestino.nombre,
                    "email": '',
                    "identificationNumber": this.modelDestino.nit,
                    "phone": this.modelDestino.celular,
                    "street": this.modelDestino.calle,
                    "number": this.modelDestino.numero,
                    "state": this.modelDestino.id_estado,
                    "postalCode": this.modelDestino.codigo_postal,
                    "reference": "",
                    // "coordinates": {
                    // 	"latitude": `${this.modelDestino.lat}`,
                    // 	"longitude": `${this.modelDestino.lgn}`
                    // }
                },
                "packages": dataPaquetes,
                "shipment": {
                    "carrier": nombreTransportadora,
                    "type": 1
                },
                "settings": {
                    "currency": "MXN"
                }
            }
            payload.id_admin_pedido = this.$route.params.id_pedido
            return payload
        },

        openModalDelete(idx){
            this.idxPaquete = idx
            this.$refs.refDeletePackage.toggle()
        },
        handleDelete(){
            this.paquetes.splice(this.idxPaquete, 1)
            this.$refs.refDeletePackage.toggle()
        },
        openModalAddPackage(){
            this.$refs.refModalAgregarPaquete.toggle()
        },
        openModalCotizarEnvioTodos(){
            this.$refs.refModalCotizarEnvioTodos.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.pedidos-formulario-envio{
	.wf-360px{
		min-width: 360px !important;
        max-width: 360px !important;
	}
	.wf-370px{
		min-width: 370px !important;
        max-width: 370px !important;
	}
	.wf-505px{
		min-width: 505px !important;
        max-width: 505px !important;
	}
	.wM-785px{
        max-width: 785px !important;
	}
	.text-decoration-underline{
		text-decoration: underline;
	}
}
</style>
